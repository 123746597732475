<template>
  <v-card>
    <v-card-title>
      SMS Gateway
    </v-card-title>

    <v-card-text>
      Toggle to enable or disable of sending SMS to users in countries below for mobile systems ONLY.

      <ul>
        <li>Any changes to the system will reflect almost instantly</li>
        <li>Check with the tech team to make sure that the account has sufficient credits</li>
        <li>Disable the SMS once your project has been completed and closed</li>
      </ul>

      <v-autocomplete
        :items="countries"
        item-text="title"
        item-value="code"
        autocomplete
        multiple
        chips
        deletable-chips
        label="Enabled Country"
        v-model="enabledCountries"
      />

      <v-btn color="primary mr-2" @click="updateSmsConfig">Update</v-btn>
    </v-card-text>
  </v-card>
</template>


<script>
import MobileRestResource from "@/services/dataServiceMobile.js";

const service = new MobileRestResource();

export default {
  data() {
    return {
      countries: [],
      enabledCountries: [],
    };
  },

  mounted() {
    service.getMobileConfig({ key: "countries" }).then(r => {
      this.countries = r.data;
    });

    service.getMobileConfig({ key: "smsEnabledCountries" }).then(r => {
      this.enabledCountries = r.data;
    });
  },

  methods: {
    updateSmsConfig() {
      this.$setLoader()
      
      service.updateMobileConfig({ 
        key: "smsEnabledCountries", 
        value: this.enabledCountries 
      }).then(r => {
        this.$showSuccessMessage(r.data)
      });
    }
  },
};
</script>